import React, { useContext } from 'react';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { Route as RouterRoute, Redirect } from 'react-router-dom';

import { AuthContext } from '../context/Auth/AuthContext';

import BackdropLoading from '../components/BackdropLoading';

const SentryRoute = Sentry.withSentryRouting(RouterRoute);

const history = createBrowserHistory();

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
  tracesSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export default function Route({ component: Component, isPrivate = false, ...rest }) {
  const { isAuth, loading } = useContext(AuthContext);

  if (loading) {
    return <BackdropLoading />;
  }

  if (!isAuth && isPrivate) {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />
      </>
    );
  }

  if (isAuth && !isPrivate) {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: '/', state: { from: rest.location } }} />;
      </>
    );
  }

  return (
    <>
      {loading && <BackdropLoading />}
      <SentryRoute {...rest} component={Component} />
    </>
  );
}
