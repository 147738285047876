function referenceComparator(v, o) {
  return v === o;
}

export function flatMapReducer() {
  return (acc, curr) => acc.concat(curr);
}

export function distinctFilter(comparator = referenceComparator) {
  return (v, i, self) => i === self.findIndex(o => comparator(v, o));
}
